<template>
  <div>
    <list-layout ref="govBrand" :filter-form="filterForm" :thead="thead"
                 :on-fetch="getList" :on-edit="onEdit"
    >
      <template #filter>
        <brand-list-filter is-gov :filterForm="filterForm" :uploadFilter="ok" />
      </template>
<!--      <template #item_sort="{row}">{{row.sort}}</template>-->
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";
import { getGovBrands } from "@/modules/gov/api/brand-library";
import BrandListFilter from "@/modules/brand-admin/components/ListFilter";

export default {
  components: {BrandListFilter, ListLayout},
  data() {
    return {
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        category_id: -1,
        category_name: '',
        keyword: "", //内容名称
        pid: -2, //所属组织
        tab: "normal", //当前筛选tab
        page_size: 15, //每页多少条数据
        project_id: this.$store.getters.tenant.id || ''
      },
      thead: [
        { label: "ID", prop: "id", width: 100 },
        {label: "所属商协会", prop: "project_name", minWidth: 100},
        { label: "内容名称", prop: "name", minWidth: 150 },
        { label: "分类", prop: "category_name", minWidth: 110 },
        {
          label: "文章数",
          prop: "articles_count",
          minWidth: 90,
          sortable: true,
        },
        {
          label: "活动数",
          prop: "activity_count",
          minWidth: 90,
          sortable: true,
        },
        {
          label: "用户收藏数",
          prop: "collect_count",
          minWidth: 120,
          sortable: true,
        },
        {
          label: "运营收藏数",
          prop: "virtual_collect",
          minWidth: 120,
          sortable: true,
        },
        {
          label: "总收藏数",
          prop: "total_count",
          minWidth: 110,
          sortable: true,
        },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 160,
          sortable: true,
        },
        // { label: "排序", prop: "sort", type: 'slot' },
        // { label: "推荐", prop: "is_recommend" },
        // { label: "显示", prop: "is_show" },
      ]
    }
  },
  methods: {
    onEdit(row) {

    },
    getList(q) {
      return getGovBrands(q)
    },
    // 筛选回调
    ok(e) {
      const pageData = { ...this.filterForm, ...e, page: 1 };
      this.$refs.govBrand.getList(pageData)
    },
  },
}
</script>

<style scoped>

</style>
