import { api } from "@/base/utils/request";

export const getGovBrands = (data) => {
  return api({
    url: '/admin/gov/Brand/index',
    method: 'post',
    data
  })
}

export const getGovBrandCategories = () => {
  return api({
    url: '/admin/gov/Brand/categories',
    method: 'post',
  })
}
