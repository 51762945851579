<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false" :onExport="isGov?null:onExport">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="内容名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入内容名称"></el-input>
        </el-form-item>
        <el-form-item v-if="!isGov" label="分类：" prop="category_id">
          <el-select v-model="category_id" placeholder="请选择分类">
            <el-option v-for="item in categoryOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-else label="分类：" prop="category_name">
          <el-select v-model="form.category_name" placeholder="请选择分类">
            <el-option v-for="item in categoryOptions" :key="item.value" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { categoryOptions } from "../api/brand-category";
import { documentBrandList } from "../api/brand-list";
import { getGovBrandCategories } from "@/modules/gov/api/brand-library";
export default {
  name: "brand-list-filter",
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    isGov: Boolean,
  },
  data() {
    return {
      rules: {},
      categoryOptions: [],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    // 导出列表
    onExport() {
      const postData = { ...this.form, export_ids: this.ids, page: this.page };
      documentBrandList(postData).catch((err) => {});
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    if (this.isGov) {
      getGovBrandCategories().then(res => {
        this.categoryOptions = res.data;
      })
    } else {
      categoryOptions()
        .then((res) => {
          this.categoryOptions = res.data;
        })
        .catch((err) => {});
    }
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    category_id: {
      get() {
        return this.form.category_id == -1 ? "" : this.form.category_id;
      },
      set(val) {
        this.form.category_id = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
